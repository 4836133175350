import { useCallback } from 'react';

import type { FilteredView } from '../types';
import { globalState } from './models';

const matchesQuery = ({ filteredView, query }: { filteredView: FilteredView; query: string }) =>
  query === filteredView.query;

export default function useCurrentFilteredView(query: string): FilteredView | undefined {
  return globalState(
    useCallback(
      (state) => {
        if (!state.persistent.filteredViews) {
          return;
        }

        const decodedQuery = decodeURIComponent(query);
        return Object.values(state.persistent.filteredViews).find((filteredView) =>
          matchesQuery({ filteredView, query: decodedQuery }),
        );
      },
      [query],
    ),
  );
}

export function useMobileCurrentFilteredView(isFilteredView = true): FilteredView | undefined {
  return globalState(
    useCallback(
      (state) => {
        if (!isFilteredView) {
          return;
        }
        const query = state.mobileSelectedFilteredViewQuery;
        const views = state.persistent.filteredViews;

        if (!query || !views) {
          return;
        }

        const decodedQuery = decodeURIComponent(query);
        return Object.values(views).find((filteredView) =>
          matchesQuery({ filteredView, query: decodedQuery }),
        );
      },
      [isFilteredView],
    ),
  );
}

export function useCurrentFilteredViewFromId(id: string): FilteredView | undefined {
  return globalState(useCallback((state) => state.persistent.filteredViews[id], [id]));
}
